@import url(https://db.onlinewebfonts.com/c/0abeb2471faeb5269db428b9eac2075e?family=GT+Walsheim+Pro+Regular+Regular);
@font-face {
  font-family: "GT Walsheim Pro Regular Regular";
  src: url("https://db.onlinewebfonts.com/t/0abeb2471faeb5269db428b9eac2075e.eot");
  src: url("https://db.onlinewebfonts.com/t/0abeb2471faeb5269db428b9eac2075e.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/0abeb2471faeb5269db428b9eac2075e.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/0abeb2471faeb5269db428b9eac2075e.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/0abeb2471faeb5269db428b9eac2075e.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/0abeb2471faeb5269db428b9eac2075e.svg#GT Walsheim Pro Regular Regular")format("svg");
}


  body {
    font-family: "GT Walsheim Pro Regular Regular" !important;
/* background: url("/images/main-background-white.svg") */
  }
